import { LOGICFLOW } from '../api'
import { METHOD, request } from '@/utils/request'

// 查询审批任务列表
export async function getRecordList(params) {
  return request(`${LOGICFLOW}/hr/record/getRecordList`, METHOD.GET, params)
}

// 查询审批记录对应的表单信息
export async function getRecordFormInfo(params) {
  return request(`${LOGICFLOW}/hr/record/getRecordFormInfo`, METHOD.GET, params)
}

// 查询当前审批的操作记录 - 时间轴
export async function getFlowRecordOperateList(params) {
  return request(`${LOGICFLOW}/record/getFlowRecordOperateList`, METHOD.GET, params)
}

// 审批当前任务
export async function approval(params) {
  return request(`${LOGICFLOW}/hr/operate/approval`, METHOD.POST, params)
}

// 撤销当前申请
export async function cancel(params) {
  return request(`${LOGICFLOW}/hr/operate/cancel`, METHOD.POST, params)
}

// 转交当前审批
export async function transfer(params) {
  return request(`${LOGICFLOW}/hr/operate/transfer`, METHOD.POST, params)
}

// 添加抄送人
export async function addCarbonCopy(params) {
  return request(`${LOGICFLOW}/hr/operate/addCarbonCopy`, METHOD.POST, params)
}

// 查询审批流程平均耗时
export async function averageProcessingTime(params) {
  return request(`${LOGICFLOW}/flowRecord/averageProcessingTime`, METHOD.GET, params)
}

// 查询所有的简单审批信息列表
export async function getFlowSimpleList(params) {
  return request(`${LOGICFLOW}/hr/operate/getFlowSimpleList`, METHOD.GET, params)
}

// 查询员工手写签名
export async function currentSign(params) {
  return request(`${LOGICFLOW}/user/sign`, METHOD.GET, params)
}

// 新增或替换当前员工手写签名
export async function replaceCurrentSign(params) {
  return request(`${LOGICFLOW}/hr/operate/replaceCurrentSign`, METHOD.POST, params)
}
